import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { SharedService } from './Utilities/shared.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppInsightService } from './Services/AppInsight.service';
// import { ConnectionService } from 'ng-connection-service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { HttpParams } from '@angular/common/http';
// import { authConfig } from './oidc-config';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { b2cPolicies, msalConfig } from './auth-config';
import { ProcessAssesmentService } from './Services/ProcessAssesment.service';
import { AuthService } from './Services/Auth.service';

// declare ga as a function to set and sent the events
declare let gtag: Function;


type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  code: string;
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;

  status = 'ONLINE';
  isConnected = true;
  NewVersion: boolean = false;


  checking: boolean = true;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  private appInsights;
  params = new HttpParams();
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  URL: string;
  title = 'OpenBots Discovery';
  isLoading = false;
  isResultsLoading = false;
  constructor(private sharedService: SharedService, public cdr: ChangeDetectorRef,
    private router: Router,
    private acRoute: ActivatedRoute,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    public authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    public service: ProcessAssesmentService,
    private _bottomSheet: MatBottomSheet,
  ) {
    this.URL = window.location.href;
    //this.URL.includes('http://localhost:4200/login')  ||
    let Queryparams = this.sortParams(this.URL);
    // if (this.URL.includes('/login')) {
    //   this.router.navigate(['login'], { queryParams: Queryparams });
    // }
    // if (this.URL.includes('/home')) {
    //   this.router.navigate(['home'], { queryParams: Queryparams });
    // }

    //googgle analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });

    //end
    this.appInsights = new AppInsightService(router);

    this.sharedService.isLoadingSource.subscribe(loading => {
      this.isLoading = loading;

      this.cdr.detectChanges();
    })


    // this.sharedService.isResultsLoadingSource.subscribe(resloading => {
    //   this.isResultsLoading = resloading;
    //   this.cdr.detectChanges();
    // })
    // this.cdr.detectChanges();
    this.sharedService.scrollSource.subscribe(data => {
      if (data !== undefined) {
        if (this.componentRef && this.componentRef.directiveRef) {
          this.componentRef.directiveRef.scrollToBottom(data.offset, data.speed);
        }
      }
    });
  }

  ngOnInit() {


    // MSAL login Flow
    if (window.location.href.startsWith(`${environment.AUTH_REDIRECT_URI}/logout`)) {
      window.location.href = `${environment.AUTH_REDIRECT_URI}/#/logout`;
      return;
    }

    this.isIframe = window !== window.parent && !window.opener;
    const accounts = this.msalService.instance.getAllAccounts();

    const queryReturnIsAuth = this.GetParam('isAuthenticated');
    const queryReturnReturn = this.GetParam('returnurl');

    if (queryReturnIsAuth == 'true') {
      if (this.URL.includes('login'))
        window.location.href = window.location.href.replace('/login', '')
    }
    if (accounts.length == 0 && queryReturnIsAuth == 'true' && !this.URL.includes('signin-oidc') && !this.URL.includes('signin-ms-oidc')) {
      this.authService.login();
    } else if (accounts.length == 0 && queryReturnIsAuth != 'true') {
      if (!this.URL.includes('signin-oidc') && !this.URL.includes('signin-ms-oidc')) {
        // window.location.href = `${environment.Azure_AD_B2C.GalaxyLoginUrl}?returnurl=${window.location.href}`;
        this.authService.login();
      } else {
        setTimeout(() => {
          this.handleRedirection()
          this.checking = false;
        }, 2000);
      }
    }

    if (accounts.length > 0) {
      this.handleRedirection()
      this.checking = false;
    }

    /**
   * You can subscribe to MSAL events as shown below. For more info,
   * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
   */
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload = result.payload as AuthenticationResult;

        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
          this.msalService.instance.setActiveAccount(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.msalService.instance.getAllAccounts()
            .find((account: AccountInfo) =>
              account.idTokenClaims?.oid === idtoken.oid
              && account.idTokenClaims?.sub === idtoken.sub
              && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
                || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
            );

          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount
          };

          // silently login again with the signUpSignIn policy
          this.msalService.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };

          this.authService.login(signUpSignInFlowRequest);
        }

        localStorage.setItem('accessToken', payload.idToken);
        // localStorage.setItem('ActiveOrganizationID', '3d212529-c2e4-4a2a-8253-82212a9101c7');

        this.setUserInfo(idtoken)
        this.checkIdentityClaims();

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.authService.login(resetPasswordFlowRequest);
        };
      });

  }


  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  setUserInfo(idTokenClaims: IdTokenClaimsWithPolicyId) {
    const claimsObj = idTokenClaims;

    console.log('claimsObj', claimsObj);

    localStorage.setItem('id_token_claims_obj', JSON.stringify(idTokenClaims));

    // console.log(this.service.claimsUserInfo);

    // const claims = JSON.parse(localStorage.getItem('id_token_claims_obj'));
    // this.service.claimsUserInfo.emails = claimsObj['emails'] as string[];
    // this.service.claimsUserInfo.extension_identity_person_id = claimsObj['extension_identity_person_id'] as string;
    // this.service.claimsUserInfo.extension_identity_user_consent = claimsObj['extension_identity_user_consent'] as string;

    // this.service.claimsUserInfo.name = claimsObj['name'] as string;
    // // this.claimsUserInfo.extension_identity_user_consent = claimsObj['extension_identity_user_consent'] as string;
    // console.log(claimsObj);

    // const organizationsClaims = claimsObj['extension_Organizations'] as string;
    // const organizationPieces = organizationsClaims.split(",");

    // if (organizationPieces && organizationPieces.length > 0) {
    //   for (const org of organizationPieces) {
    //     this.service.claimsUserInfo.myOrganizations.push({
    //       name: org.split('~')[0],
    //       tenantKey: org.split('~')[1]
    //     })
    //   }
    // }

    // this.service.UserInfo$.next(this.service.claimsUserInfo);

    // console.log('this.service.claimsUserInfo ', this.service.claimsUserInfo);

  }

  GetParam(name: any) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }


  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  handleRedirection() {
    if (!this.URL.includes('signin-oidc')) {
      if (window.location.hash == "#/" || window.location.hash == "#/navigator/dashboard") {
        this.checkIdentityClaims(true, true);
      } else if (window.location.hash != '#/navigator/dashboard') {
        var url = window.location.hash.substring(2);
        if (url == "") {
          this.checkIdentityClaims(false, true);
        } else if (url.includes('?selectedIndex')) {
          this.router.navigateByUrl(url);
        } else {
          this.router.navigate([url]);
        }
      }
    } else {
      // this.router.navigate(['navigator/dashboard']);
      this.router.navigate(['']);
    }
    this.checking = false;
  }

  configureSignOn() {
    // this.oauthService.configure(authConfig);
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // this.oauthService.loadDiscoveryDocumentAndLogin().then((success) => {
    //   //Login Successful
    //   // console.log('login success', success);
    //   if (success) {
    //     setTimeout(() => {
    //       this.checkIdentityClaims();
    //     }, 2000);
    //   }
    // });
  }

  CheckVersionNumber() {
    //localStorage.setItem('accessToken', data.token);
    //localStorage.getItem('AutomationNavigatorVersion');
    this.sharedService.CheckVersionNumber().subscribe(
      (data: any) => {
        if (localStorage.getItem('AutomationNavigatorVersion') == null) {
          localStorage.setItem('AutomationNavigatorVersion', data);
          this.NewVersion = true;
          window.location.reload();
        }
        else {
          if (localStorage.getItem('AutomationNavigatorVersion') != data) {
            localStorage.setItem('AutomationNavigatorVersion', data);
            //this.NewVersion=true;
            window.location.reload();
          }
          else { this.NewVersion = false; }
        }
      },
      error => { }
    );
  }

  sortParams(link) {
    if (this.URL.includes('?')) {
      let queryParams = this.URL.split('?')[1];
      let params = queryParams.split('&');
      let pair = null;
      let data = {};
      params.forEach((d) => {
        pair = d.split('=');
        data[`${pair[0]}`] = pair[1];
      });
      return data;
    }
    else {
      return ''
    }
  }

  checkIdentityClaims(checking = false, navigate = false) {
    // if loader is visible and navigate with angular router
    // some logic behind this. The angular navigate not working properly
    //if angular oidc redirects to dashboard
    if (navigate) {
      if (checking) {
        this.router.navigate(['navigator/dashboard']).then(() => {
          // console.log('here');
          this.checking = false;
        });
      } else {
        this.router.navigate(['navigator/dashboard']);
      }
    } else {
      this.checking = false;
      const url = environment.AUTH_REDIRECT_URI + '/#/navigator/dashboard';
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      location.reload();
    }
  }
}

